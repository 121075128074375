<template>
  <div>
    <p class="has-text-weight-bold is-size-4 mb-1 overview-title">
      {{
        isMortgage
          ? $t("af:mortgage_application.overview.title")
          : $t("af:application.overview.title")
      }}
    </p>
    <p class="mb-5 overview-description">
      {{
        isMortgage
          ? $t("af:mortgage_application.overview.subtitle")
          : $t("af:application.overview.subtitle")
      }}
    </p>

    <section class="af-section" v-if="!loaded">
      <article class="media" v-for="ll in 3" :key="ll">
        <figure class="media-left">
          <p class="image is-64x64">
            <b-skeleton circle width="64px" height="64px"></b-skeleton>
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <b-skeleton active></b-skeleton>
              <b-skeleton height="80px"></b-skeleton>
            </p>
          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              <a class="level-item">
                <span class="icon is-small">
                  <b-skeleton></b-skeleton>
                </span>
              </a>
              <a class="level-item">
                <span class="icon is-small">
                  <b-skeleton></b-skeleton>
                </span>
              </a>
            </div>
          </nav>
        </div>
      </article>
    </section>

    <section class="af-section" v-if="loaded">
      <div
        class="af-tile is-flex is-align-items-center"
        v-for="field in fields"
        v-bind:key="field.identifier"
      >
        <div class="mb-1 overview-block">
          <div class="overview-question">{{ field.question }}</div>

          <div class="has-text-weight-medium overview-answer">
            {{ field.answer }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  FLOW_POSITION,
  FLOW_QUESTION_FIELD_TYPE,
  SIMPLE_FIELD_TYPE,
} from "@/utils/const";
import { mapActions, mapGetters } from "vuex";
import { formatDateWithDots } from "@/utils/util";

export default {
  name: "ApplicationFlowOverview",
  components: {},
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      FLOW_POSITION,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      simpleFieldGetter: "simpleFieldStore/get",
    }),
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
    fields() {
      let fields = [];
      for (let s in this.steps) {
        let step = this.steps[s];
        for (let q in step.questions) {
          let question = this.steps[s].questions[q];
          if (question.fieldType === FLOW_QUESTION_FIELD_TYPE.SIMPLE_FIELD) {
            let qData = this.simpleFieldGetter(question.fieldId);
            if (qData) {
              let answer = this.isMortgage
                ? this.$store.getters[
                    "mortgageApplication/getFlowQuestionAnswer"
                  ](qData.identifier)
                : this.$store.getters["loanApplication/getFlowQuestionAnswer"](
                    qData.identifier
                  );
              if (answer) {
                if (
                  qData.type === SIMPLE_FIELD_TYPE.CHECKBOX ||
                  qData.type === SIMPLE_FIELD_TYPE.RADIO ||
                  qData.type === SIMPLE_FIELD_TYPE.SELECT
                ) {
                  if (answer.startsWith("[")) {
                    answer = answer.replace("[", "");
                    answer = answer.replace("]", "");
                    answer = answer.replaceAll(/\"/g, "");
                    answer = answer.replaceAll(/\s/g, "");
                    answer = answer.split(",");
                    let multiAnswer = [];
                    for (let a in answer) {
                      for (let i in qData.inputValues) {
                        if (qData.inputValues[i].value === answer[a]) {
                          multiAnswer.push(qData.inputValues[i].text);
                          break;
                        }
                      }
                    }
                    answer = multiAnswer.join("; ");
                  } else {
                    for (let i in qData.inputValues) {
                      if (qData.inputValues[i].value === answer) {
                        answer = qData.inputValues[i].text;
                        break;
                      }
                    }
                  }
                }
              }
              if (qData.type === SIMPLE_FIELD_TYPE.DATE_PICKER) {
                answer = formatDateWithDots(answer);
              }
              if (answer) {
                fields.push({
                  question: qData.label,
                  answer: answer,
                  identifier: qData.identifier,
                  index: step.rowIndex * 1000 + question.rowIndex,
                });
              }
            }
          }
        }
      }
      fields = fields.sort((a, b) => a.index - b.index);
      return fields;
    },
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    async steps() {
      await this.fetchData();
    },
  },
  methods: {
    ...mapActions({
      fetchSimpleField: "simpleFieldStore/fetchOne",
    }),
    async fetchData() {
      for (let s in this.steps) {
        for (let q in this.steps[s].questions) {
          let question = this.steps[s].questions[q];
          if (question.fieldType === FLOW_QUESTION_FIELD_TYPE.SIMPLE_FIELD) {
            let qData = this.simpleFieldGetter(question.fieldId);
            if (!qData) {
              await this.fetchSimpleField(question.fieldId);
            }
          }
        }
      }
      if (this.steps.length > 0) {
        this.loaded = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.overview-block {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-bottom: 1px #ecf0f6 solid;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.overview-question {
  color: #abbacd;
}

.overview-answer {
  text-align: right;
  float: right;
}

.mbh {
  .overview-title {
    font-size: 32px !important;
    font-weight: 800;
    line-height: 56px;
    color: #212121;
    margin-bottom: 32px !important;
  }

  .overview-description {
    color: #051c3a;
    font-size: 16px;
  }
}
</style>
